
import { defineComponent } from "vue";
import Share from "./share.vue";

export default defineComponent({
  components: { Share },
  setup() {
    const shareface = () => {
      const url = window.location.href;
      window.FB.ui(
        {
          display: "popup",
          method: "share",
          href: url,
        },
        function () {
          console.log("ok");
        }
      );
    };
    return { shareface };
  },
});
