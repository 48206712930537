export default {
  links:[
    {
      title: "DotAd",
      link: "",
      desc: "Maodong founded DotAd in 1998, which became the largest SMS application and leading 2G company in China. In 2006, DotAd was acquired for $30M ($60M after the acquisition due to increase in share price).",
      
    },
    {
      title: "EGLS",
      link: "https://www.pocketgamer.biz/interview/50001/china-spotlight-egls-on-taking-the-asian-success-of-armed-heroes-to-the-west/",
      desc: "In 2007, Maodong co-founded EGLS, a prominent mobile game developer. EGLA was acquired by a public company in 2015 for $500M.",
    },
    {
      title: "LMobile",
      link: "https://pitchbook.com/profiles/company/63331-21",
      desc: "Maodong founded LMobile in 2008, which had Softbank Asia Investment Bank (SAIF) as one of its investors. LMobile became the largest mobile MMS advertising platform and leading 2.5G company in China. In 2021, Lmobile was acquired by Telstra for $159M.",
    },
    {
      title: "Welink",
      link: "https://baike.baidu.com/item/北京百分通联信息技术有限公司/2119096",
      desc: "In 2010, Maodong founded Welink. Welink became a leader as a mobile marketing platform and 3G and was ultimately sold in 2015 for $110M.",
    },
    {
      title: "Wowo",
      link: "https://www.prnewswire.com/news-releases/wowo-limited-evolves-into-all-around-mobile-e-commerce-platform-275718811.html",
      desc: "In 2010, Madong founded WoWo in 2010, which received $100M investment from CDH, Zero2IPO, and Bauhinia Capital. Wowo became a leading lifestyle service e-commerce platform. Maodong successfully led the company to list on the Nasdaq in 2015.",
    },
    {
      title: "Galaxy Internet",
      link: "https://www.prnewswire.com/news-releases/galaxy-group-expands-global-start-up-service-network-with-us-30-million-investment-in-ip-group-300472982.html",
      desc: "In 2011, Maodong founded Galaxy Internet, which received $350 million in funding from CSC and several other private equity firms, becoming a leading industrial internet group, it was valued at $1.9B in 2016.",
    },
    {
      title: "Roxe",
      link: "https://roxe.io",
      desc: "As President of Roxe, Maodong aims Roxe to become a leading blockchain-based payments infrastructure company. Roxe is on its way to becoming a U.S. publicly listed company.",
    },
  ],
  links_two:[
    {
      title: "Digital asset management systems and methods",
      link: "https://patents.justia.com/patent/20220130005",
    },
    {
      title: "Trusted tokenized transactions in a blockchain system",
      link: "https://patents.justia.com/patent/11250507",
    },
    {
      title:
        "Adjusting transaction distribution based on predicted open transaction execution rates",
      link: "https://patents.justia.com/patent/11042849",
    },
    {
      title: "Integer conversion for locally stored data in priority queues",
      link: "https://patents.justia.com/patent/20200274830",
    },
    {
      title: "Trusted tokenized transactions in a blockchain system",
      link: "https://patents.justia.com/patent/20200265516",
    },
    {
      title: "Point-to-point distributed decentralized system",
      link: "https://patents.justia.com/patent/20200250168",
    },
    {
      title: "Method for user identity tracking",
      link: "https://patents.justia.com/patent/20090258637",
    },
  ],
  links_three:[
    {
      title: "2017 People’s list of influence China in Beijing",
      link: "http://news.sina.com.cn/c/2017-12-19/doc-ifypsvkp4950906.shtml",
    },
    {
      title:
        "Xu Maodong takes root in the industrial Internet to help traditional industries upgrade",
      link: "https://www.sohu.com/a/225229742_120079",
    },
    {
      title:
        "Xu Maodong, Chairman of Galaxy Internet, came to Luoyang to talk about Internet thinking",
      link: "http://news.lyd.com.cn/system/2015/01/12/010369590.shtml?utm_source=tuicool&utm_medium=referral",
    },
    {
      title: "Galaxy Group and Xincheng District of Xi'an announce strategic partnership",
      link: "https://kknews.cc/zh-hk/other/b69364j.html",
    },
  ],
  cards:[{
    img:'',
    title:'BuyUcoin pilots Roxe payment node in India',
    date:'June 27, 2022',
    info:'5 min read'
  },{
    img:'',
    title:'BuyUcoin pilots Roxe payment node in India',
    date:'June 27, 2022',
    info:'5 min read'
  }]
}