
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  reactive,
  nextTick,
} from "vue";
import Header from "@/components/header/index.vue";
import Tail from "@/components/tail/index.vue";
import Bar from "./bar.vue";
import Link from "./lk.vue";
import Card from "./card.vue";
import Share from "./share.vue";
import Links from "./data";

export default defineComponent({
  name: "Home",
  components: { Header, Tail, Bar, Link, Card, Share },
  setup() {
    const bkcolor = ref("");
    const winScroll = () => {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 180) {
        bkcolor.value = "dark";
      } else {
        bkcolor.value = "";
      }
    };
    onMounted(() => {
      window.addEventListener("scroll", winScroll);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("scroll", winScroll);
    });
    const gotolink = (url: string) => {
      window.open(url);
    };
    const { cards, links_three, links_two, links } = Links;
    return { cards, links_three, links_two, links, gotolink, bkcolor };
  },
});
