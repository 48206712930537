
import { defineComponent } from 'vue'

export default defineComponent({
  props:["title","desc","link"],
  setup(props) {
    const tolink = () => {
      if(props.link) {
        window.location.href = props.link;
      }
    }
    return {tolink}
  }
})
