
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  setup() {
    const shareface = () => {
      const url = window.location.href;
      window.FB.ui(
        {
          display: "popup",
          method: "share",
          href: url,
        },
        function () {console.log('ok')}
      );
    };
    const url = encodeURIComponent(window.location.href);
    const cpyurl = () => {
      const  input = document.createElement("input");
      input.setAttribute("readonly",'readonly');
      input.value = window.location.href;
      document.body.appendChild(input);
      input.setSelectionRange(0,999);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
    }
    const list = ['share to facebook','share to twitter','share to linkedin','copy to clipboard']
    const popstype = reactive({
      left:'-50px',
      display:'none',
      text:'share to facebook',
      width:'150px'
    })
    const leaveface = () => {
      popstype.display = 'none';
    }
    const inface  = (idx=0) => {
      popstype.left = `${idx*37-50}px`
      popstype.display = 'block';
      popstype.text = list[idx];
    }
    return { inface,leaveface,shareface,url,cpyurl,popstype };
  },
})
