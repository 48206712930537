import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from './alk.svg'


const _withScopeId = n => (_pushScopeId("data-v-61c6f1d9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lk" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.tolink && _ctx.tolink(...args))),
      class: "lk-t"
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.link?'link':'')
      }, _toDisplayString(_ctx.title), 3),
      (_ctx.link)
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, _toDisplayString(_ctx.desc), 1)
  ]))
}