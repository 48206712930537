
import { computed, defineComponent } from 'vue'
const defaultlink  = require('./card1.png');

export default defineComponent({
  props:['data'],
  setup(props) {
    const linkto = () => {
      if(!props.data.url) return;
      window.location.href = props.data.url;
    }
    const imglink = computed(()=>{
      return props.data.img || defaultlink;
    })
    return {linkto,imglink}
  }
})
